/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/**
 * Adding process.nextTick implementation because the "touchtone" dependency needs it
 *
 * Note: "process" is not available in the browser by default, only in Node environments
 */
(window as any).process = {};
(window as any).process.nextTick = (function () {
  const canSetImmediate = typeof window !== 'undefined' && window.setImmediate;
  const canPost =
    typeof window !== 'undefined' &&
    window.postMessage &&
    window.addEventListener;
  if (canSetImmediate) {
    return function (f: any) {
      return window.setImmediate(f);
    };
  }
  if (canPost) {
    const queue: any[] = [];
    window.addEventListener(
      'message',
      function (ev) {
        const source = ev.source;
        if (
          (source === window || source === null) &&
          ev.data === 'process-tick'
        ) {
          ev.stopPropagation();
          if (queue.length > 0) {
            const fn = queue.shift();
            fn();
          }
        }
      },
      true
    );
    return function nextTick(fn: any) {
      queue.push(fn);
      window.postMessage('process-tick', '*');
    };
  }
  return function nextTick(fn: any) {
    setTimeout(fn, 0);
  };
})();
